<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>元数据管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/coalSystemManage-systemManage' }">
        {{crumbA}}
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/coalSystemManage-subSystemManage' }">
        {{crumbB}}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="crumbB!=crumbC" :to="{ path: '/coalSystemManage-deviceManage' }">
        {{crumbC}}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="crumbC!=crumbD">
        {{crumbD}} 
      </el-breadcrumb-item>
	  
    </el-breadcrumb>
	<over-view-of-equipment :table-data="tableData" :total="total" :totalMPNum="totalMPNum" v-if="overViewFlag"></over-view-of-equipment>
    <!--    <span style="font-size: 12px">项目系统概述：</span>-->
    <!--    <div class="coalblock" v-for="item in options" :key="item.title">-->
    <!--      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>-->
    <!--      <br />-->
    <!--      <span style="font-size: 24px">{{ item.num }}</span>-->
    <!--    </div>-->
    <!--    <coal-over-view></coal-over-view>-->
    
    <el-form :inline="true">
      <el-form-item>
        <el-button v-if="isAuth('subEquipment:add') && setting.backups" type="primary" @click="addSubEquipment">增加附属设备</el-button>
        <el-button v-if="isAuth('subEquipment:delete') && setting.batchremove" type="danger">批量删除系统</el-button>
      </el-form-item>
      
      <div style="position: relative;" @click="showPanel('startPointId')">
		  <span style="font-size: 16px">搜索：</span>
		  <el-input style="display: inline-block;width: 500px;z-index: 111;" type="text" v-model="selectedOptions" suffix-icon="el-icon-arrow-down" placeholder="请选择设备"></el-input>
		  <el-cascader
			id="startPointId"
		      :options="parentOption"
		      separator=" | "
		      style="width: 500px"
		      :props="props"
		      placeholder="请选择设备"
		      clearable
		      ref="cascade"
		      filterable 
		      @change="handleSearch()"
		  ></el-cascader>
	  </div>
    </el-form>
    <br>
    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          min-width="5%"
          v-if="setting.batchremove"
      >
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="附属设备id"
          header-align="center"
          align="center"
          min-width="10%"
          v-if="false"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="附属设备名称"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="measurePointCount"
          label="数据项个数"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="createdate"
          label="创建日期"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="enabled"-->
      <!--          label="使用状态"-->
      <!--          header-align="center"-->
      <!--          align="center"-->
      <!--          min-width="10%"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleCheck(scope.row)"-->
          <!--              :disabled="scope.row.subSystemCount == 0?true:false"-->
          <!--          >查看-->
          <!--          </el-button>-->
          <el-button
              type="text"
              size="small"
              @click="handleCheck(scope.row)"
          >查看
          </el-button>
          <el-button
              v-if="isAuth('subEquipment:update')"
              type="text"
              size="small"
              @click="updateHandle(scope.row)"
          >修改</el-button>
          <el-button
              v-if="isAuth('subEquipment:delete')"
              type="text"
              size="small"
              style="color: red"
              @click="deleteSubEquipment(scope.row.id)"

          >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          header-align="center"
          align="center"
          min-width="20%"
      >
      </el-table-column>
    </el-table>

    <add-sub-equipment
        v-if="subEquipmentDialog"
        ref="addSubEquipment"
        @update="update"
    ></add-sub-equipment>

    <update-sub-equipment
        v-if="updateVisible"
        ref="updateSubEquipment"
        @refreshDataList="initDataWithGlobalVar"
    ></update-sub-equipment>
    <!-- 分页区域 -->
    <div style="display: flex; justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import addSubEquipment from "./addSubEquipment";
import CoalOverView from "./coalOverView";
import updateSubEquipment from "./updateSubEquipment";
import OverViewOfEquipment from "./overViewOfEquipment";

export default {
  name: "subDeviceManage",
  data() {
    let that = this;
    return {
      name:'项目附属设备',
      dataListLoading: false,
      subEquipmentDialog: false,
      parentOption: [],
	  selectedOptions:"",
      dataListSelections: [],
      // options: [
      //   {
      //     title: "总数据量",
      //     num: "1.2T",
      //   },
      //   {
      //     title: "系统个数",
      //     num: "8个",
      //   },
      //   {
      //     title: "子系统系统个数",
      //     num: "12个",
      //   },
      //   {
      //     title: "设备个数",
      //     num: "125",
      //   },
      // ],
      tableData: [],
      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 0, //全部数据量
      totalMPNum: 0, //全部数据项个数

      currOperator: '',
      currOperatorName: '',
      setting: {
        backups: "",
        batchremove: "",
      },

      updateVisible: false,

      overViewFlag: false,

      crumbA: '',
      crumbB: '',
      crumbC: '',
      crumbD: '',
      sizeFromSet: 10,
      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value);
            that.getSystem(value, (list2) => {
              console.log(list2);
              if (list2.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 2) {
            let { value } = node;
            console.log(value);
            that.getSubSystem(value, (list3) => {
              console.log(list3);
              if (list3.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                }]
                resolve(arr);
              } else {
                let arr = list3.map((e) => ({ value: e.id, label: e.name }));
                resolve(arr);
              }
            });
          }
          if (level == 3) {
            let { value } = node;
            console.log(value);
            that.getEquipment(value, (list4) => {
              console.log(list4);
              if (list4.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                  leaf: true,
                }]
                resolve(arr);
              } else {
                let arr = list4.map((e) => ({
                  value: e.id,
                  label: e.name,
                  leaf: true,
                }));
                resolve(arr);
              }
            });
          }
        },
      },
    };
  }, 
  mounted() {
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    //根据名字查询系统设置中的结果
    this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.backups = resp.data.backups;
        this.setting.batchremove = resp.data.batchremove;
        this.sizeFromSet = resp.data.numberperpage;
        this.size = this.sizeFromSet
      }
    });
    if(this.global.equipment_id === 0) {
      this.initData_()
    } else {
      this.initDataWithGlobalVar()
    }
    // if (this.$route.query.hasOwnProperty("equipmentId")) {
    //   this.handleJump();
    // } else {
    //   this.initData();
    // }
  },
  components: {
    OverViewOfEquipment,
    CoalOverView,
    addSubEquipment,
    updateSubEquipment
  },
  methods: {
	showPanel(val){
	   document.getElementById(val).click()
	},
    currentChange(currentPage) {
      this.page = currentPage;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("equipmentId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("equipmentId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    resetSizePage() {
      this.size = this.sizeFromSet
      this.page = 1
    },
    initData_() {
      if (this.global.coal_id === 0) {
        this.getRequest("/dataList/getAllCoal/").then((resp) => {
          if (resp.data) {
            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_COAL, resp.data[0].id, resp.data[0].coal)
            // 处理面包屑
            this.crumbA = this.global.coal_name
          }
        }).then(() => {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name

              this.getRequest(
                  "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
              ).then((resp) => {
                if (resp.data) {
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbC = this.global.subSystem_name
                  this.getRequest(
                      "/coalManage/getEquipmentInfoBySubSystemId/?subSystemId=" + this.global.subSystem_id
                  ).then((resp) => {
                    if (resp.data) {
                      // 处理全局变量
                      this.$changeGlobalVar(this.global.MODE_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                      // 处理面包屑
                      this.crumbD = this.global.equipment_name
                      this.initDataWithGlobalVar()
                    }
                  })
                }
              })
            }
          })
        })
      }else {
        if (this.global.system_id === 0) {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name
              this.getRequest(
                  "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
              ).then((resp) => {
                if (resp.data) {
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbC = this.global.subSystem_name
                  this.initDataWithGlobalVar()
                }
              })
            }
          })
        } else {
          if (this.global.subSystem_id === 0) {
            this.getRequest(
                "/coalManage/getSubSystemInfoBySystemId/?systemId=" + this.global.system_id
            ).then((resp) => {
              if (resp.data) {
                console.log("check:", resp.data)
                // 处理全局变量
                this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, resp.data[0].id, resp.data[0].name)
                // 处理面包屑
                this.crumbC = this.global.subSystem_name
              }
            })
          } else {
            if (this.global.equipment_id === 0) {
              this.getRequest(
                  "/coalManage/getEquipmentInfoBySubSystemId/?subSystemId=" + this.global.subSystem_id
              ).then((resp) => {
                if (resp.data) {
                  console.log("check:", resp.data)
                  // 处理全局变量
                  this.$changeGlobalVar(this.global.MODE_EQUIPMENT, resp.data[0].id, resp.data[0].name)
                  // 处理面包屑
                  this.crumbD = this.global.equipment_name
                }
              }).then(() => {
                this.initDataWithGlobalVar()
              })
            }
          }
        }
      }
    },
    initDataWithGlobalVar() {
      this.dataListLoading = true
      // 处理面包屑
      this.crumbA = this.global.coal_name
      this.crumbB = this.global.system_name
      this.crumbC = this.global.subSystem_name
      this.crumbD = this.global.equipment_name
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
          this.global.equipment_id + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.totalMPNum = resp.data.totalMPNum;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleSearch() {
      this.resetSizePage()
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.initDataWithGlobalVar()
      } else {
        //处理面包屑
        this.crumbA = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].label
        this.crumbB = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].label
        this.crumbC = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].label
        this.crumbD = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[3].label

        // 处理全局变量
        this.$changeGlobalVar(this.global.MODE_COAL, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].value, this.crumbA)
        this.$changeGlobalVar(this.global.MODE_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].value, this.crumbB)
        this.$changeGlobalVar(this.global.MODE_SUB_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[2].value, this.crumbC)
        this.$changeGlobalVar(this.global.MODE_EQUIPMENT, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[3].value, this.crumbD)
		let arr=[...new Set([this.crumbA,this.crumbB,this.crumbC,this.crumbD])];
		this.selectedOptions=arr.toString().replaceAll(","," | ");  
		this.$forceUpdate();
        this.initDataWithGlobalVar()
		
      }
    },
    handleCheck(row) {
      this.global.sub_equipment_id = row.id
      this.global.sub_equipment_name = row.name
      this.$router.push({
        name: 'coalSystemManage-measurePointManage',
      });
      // this.$router.push({
      //   name: 'coalSystemManage-measurePointManage',
      //   query: {
      //     subEquipmentId: row.id
      //   }
      // });
    },

    initData() {
      this.dataListLoading = true
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size + "&userId=" + this.currOperator
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
      // this.getRequest("/coalSystemManage/getSystemOverview").then((resp) => {
      //   if (resp.data) {
      //     this.options[0].num = resp.data.datasize + "G";
      //     this.options[1].num = resp.data.systemCount + "个";
      //     this.options[2].num = resp.data.subSystemCount + "个";
      //     this.options[3].num = resp.data.equipmentCount + "个";
      //   }
      // });
    },
    async getAllCoal(callback) {
      this.getRequest("/dataList/getAllCoal/").then((resp) => {
        if (resp.data) {
          callback(resp.data);
          console.log(resp.data);
        }
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest(
          "/coalManage/getSystemInfoByCoalId?coalId=" + coalId
      ).then((resp) => {
        if (resp.data) {
          console.log(resp.data);
          callback(resp.data);
        }
      });
    },
    async getSubSystem(systemId, callback) {
      this.getRequest(
          "/coalManage/getSubSystemInfoBySystemId?systemId=" + systemId
      ).then((resp) => {
        if (resp.data) {
          console.log(resp.data);
          callback(resp.data);
        }
      });
    },
    async getEquipment(subSystemId, callback) {
      this.getRequest(
          "/coalManage/getEquipmentInfoBySubSystemId?subSystemId=" + subSystemId
      ).then((resp) => {
        if (resp.data) {
          console.log(resp.data);
          callback(resp.data);
        }
      });
    },
    handleChange() {
      this.dataListLoading = true
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.getRequest(
            "/subEquipmentManage/getSubEquipmentInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      } else {
        this.getRequest(
            "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
            this.$refs["cascade"].getCheckedNodes()[0].value + "&page=" + this.page + "&size=" + this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      }
    },
    handleJump() {
      this.dataListLoading = true;
      this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" +
          this.$route.query.equipmentId + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    addSubEquipment() {
      this.subEquipmentDialog = true;
      this.$nextTick(() => {
        this.$refs.addSubEquipment.init();
      });
    },
    update() {
      this.initDataWithGlobalVar();
    },
    // 复选框
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 删除附属设备
    deleteSubEquipment(id) {
      // this.deleteMeasurePoint(id);
      let ids = "?";
      this.$confirm(
          `确定对附属设备进行[${id ? "删除" : "批量删除"}]操作?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            if (id) {
              this.deleteMeasurePoint(id);
              console.log("123");
              ids = "?ids=" + id;
            } else {
              this.dataListSelections.forEach((item) => {
                // 先删除所有数据项
                this.deleteMeasurePoint(item.id);
                ids += "ids=" + item.id + "&";
              });
            }
            this.deleteRequest(
                "/subEquipmentManage/deleteSubEquipment/" + ids
            ).then((resp) => {
              console.log("resp.data", resp.data);
              if (resp.data) {
                //this.handleChange();
                this.initDataWithGlobalVar();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 删除附属设备下的所有数据项
    async deleteMeasurePoint(id) {
      let ids = "?";
      let measurePoint = await this.getRequest(
          "/measurePointManage/getMeasurePointInfoBySubEquipmentId?subEquipmentId=" +
          id
      );
      if (measurePoint.length) {
        measurePoint.forEach((item) => {
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/measurePointManage/deleteMeasurePoint/" + ids
        );
      }
    },
    updateHandle (row) {
      this.updateVisible = true
      this.$nextTick(() => {
        this.$refs.updateSubEquipment.init(row)
      })
    },
  },
};
</script>

<style scoped>
	
	#startPointId {
	  left: -500px;
	  opacity: 0;
	}
</style>
